<template>
  <div>
    <div v-if="isAuth">
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-6">
            <label for="oldpass"
              ><b>{{ $t("chpass.OldPass") }}</b></label
            >
            <b-form-input
              id="oldpass"
              type="password"
              :placeholder="$t('chpass.OldPassPh')"
              v-model="userOldPass"
              required
            />

            <label for="psw"
              ><b>{{ $t("chpass.NewPass") }}</b></label
            >
            <b-form-input
              id="psw"
              type="password"
              :state="pswState"
              :placeholder="$t('chpass.NewPassPh')"
              v-model="userNewPass"
              required
            />

            <label for="psw2"
              ><b>{{ $t("chpass.NewPass2") }}</b></label
            >
            <b-form-input
              id="psw2"
              type="password"
              :state="spswState"
              :placeholder="$t('chpass.NewPassPh2')"
              v-model="userNewPass2"
              required
            />

            <vue-recaptcha class="my-2"
              ref="recaptcha"
              @verify="onVerify"
              @expired="onExpired"
              :sitekey="$recaptchaKey"
              :loadRecaptchaScript="true"
            >
            </vue-recaptcha>

            <b-button class="mt-3" variant="primary" @click="submitLogin" :disabled="submitFormDisabled">
              {{ $t("chpass.submit") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "PasswordChange",
  components: { VueRecaptcha },
  data() {
    return {
      isAuth: this.$store.getters.getIsAuthenticated,
      userOldPass: "",
      userNewPass: "",
      userNewPass2: "",
      token: "",
      cantSubmit: true,
      errored: false,
    };
  },
  computed: {
    pswState() {
      return this.userNewPass.length > 7 ? true : false;
    },
    spswState() {
      if (this.userNewPass2.length < 8) {
        return false;
      }
      return this.userNewPass == this.userNewPass2;
    },
    submitFormDisabled() {
        if (this.cantSubmit === true) return true;
        if (this.userOldPass.length < 4) return true;
        if (this.userNewPass.length < 8) return true;
        return this.userNewPass != this.userNewPass2;
    }
  },
  methods: {
    submitLogin() {
      if (this.userNewPass != this.userNewPass2) {
        console.log(
          "this.userNewPass != this.userNewPass2",
          this.userNewPass,
          this.userNewPass2
        );
        return;
      }
      let sendData = {
        OldPassword: this.userOldPass,
        NewPassword: this.userNewPass,
        Token: this.token,
      };
      console.log("submitPasswordChange:", sendData);
      if (this.token !== "") {
        this.$store
          .dispatch("sendAuthenticatedPost", {
            endpoint: "/user/passwordupdate",
            sendData: sendData,
          })
          .then(() => {
            this.loading = false;
            this.errored = false;
            this.$router.push("/mazdaapp");
          })
          .catch((error) => {
            console.log("Error", error);
            this.loading = false;
            this.errored = true;
          });
      }
    },
    onVerify: function (response) {
      this.token = response;
      if (this.$devmode) console.log("Verify: " + response);
      this.cantSubmit = false;
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
      console.log("Expired");
      this.cantSubmit = true;
    },
  },
  mounted() {
    if (this.isAuth == false) {
      this.$router.push('/login')
    }
  }
};
</script>